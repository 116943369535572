@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Ubuntu+Condensed&family=Ubuntu+Mono&display=swap');

:root {
  --green: #c0f820;
  --bg: #ffffff;
  --text: #111111;
 
;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu Mono', monospace;
  user-select: none;
}
a{
 text-decoration: none;
 color: #000000;
}
html,
body {
  overflow: hidden;
  box-sizing: border-box;
  background-color: var(--bg);
}
.fullPage{
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.titleCont{
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  zoom: 1;
  border-bottom: 2px solid var(--text);
}
.container {
  margin: 0px;
  position: relative;
  width: 1000px;
  
  /* height:500px; */
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 10px;
  zoom: 1;
  border-bottom: 2px solid var(--text);
}

.buttonsgroup{
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  column-gap: 4px;
}
.button{
  background-color: var(--green);
  padding: 2px 10px;
  border-radius: 6px;
  font-size: 12px;
  border: 1px solid var(--text);
  transition-duration: 0.1s;
  user-select: none;
}

.button:hover {
  background-color: var(--bg); /* Green */
  color: var(--text)!important;
  box-shadow: 0 5px #999;
  transform: translateY(-3px);
}
.button:active {
  background-color: white;
  color: black;
  box-shadow: 0 0px #ccc;
  transform: translateY(0px);
}

.citiesGroup {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  z-index: 14;
}
.cityName {
  position: relative;
  height: 50px;
  width: 180px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: var(--bg);
  border-radius: 6px;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.slidersGroup {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  z-index: 8;
  /* border: 1px solid red; */
}
.slidingBar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 4;
  
 /* background-color: rgb(0, 76, 86); */
}
.hoursRow {
  position: relative;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: start;
  z-index: 1;
}
.bottomLine {
  border-bottom: 2px solid var(--text);
}
.yourSlide {
  position: relative;
  transition: transform .8s;
  will-change: transform;
}
.milanSlide {
  transform: translateX(0px);
  position: relative;
  transition: transform 1.4s;
  will-change: transform;

}

.dcSlide {
  transform: translateX(0px);
  position: relative;
  transition: transform 1.2s;
  will-change: transform;
}
.stgoSlide {
  position: relative;
  transition: transform 1.6s;
  will-change: transform;
}
.califSlide {
  transform: translateX(0px);
  position: relative;
  transition: transform 1s;
  will-change: transform;
}

.hrsSVG {
  width: 600px;
  height: 50px;
}

.markerBg {
  position: absolute;
  top: 10px;
  left: 348px;
  height: 358px;
  width: 44px;
  border: 2px solid rgba(0, 0, 0, 1);
  /* border: 2px solid rgba(255, 179, 166, 0.5); */
  background-color: rgba(0, 0, 0, 1);
  border-radius:6px;
  z-index: 3;
}
.nowIsh {
  position: relative;
  top: 0px;
  left: 0px;
  width:  40px;
  z-index: 2;
}

.fadeLeft {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 300px;
  height: 500px;
  z-index: 9;

  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
    /* rgba(238, 238, 238, 1) 50%,
    rgba(238, 238, 238, 0) 100% */
  );
}
.fadeRight {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 300px;
  height: 500px;
  z-index: 9;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}



.footerText{
  width: 100%;
  color: #666;
  /* background-color: red; */
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 6px;
}


@media only screen and (max-width: 900px) {
  .container {
    margin: 0px;
    width: 900px;
    zoom: 0.8;
  }
  /* .slidersGroup {
    left: -100px;
  } */
  .markerBg {
    left: 250px;
  }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .container {
    margin: 0px;
    width: 550px;
    zoom: 0.75;
  }
  /* .slidersGroup {
    left: -100px;
  } */
  .markerBg {
    left: 250px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .container {
    zoom: 1.2;
  }
  /* .slidersGroup {
    left: 0px;
  } */
  /* .markerBg {
    left: 350px;
  } */
}
@media only screen and (min-width: 1300px) {
  .titleCont{
    zoom: 1.28;
  }
  .container {
    zoom: 1.28;
  }
}
@media only screen and (min-width: 1400px) {
  .container {
    zoom: 1.38;
  }
  .titleCont{
    zoom: 1.38;
  }
}
@media only screen and (min-width: 1500px) {
  .container {
    zoom: 1.48;
  }
  .titleCont{
    zoom: 1.48;
  }
}
@media only screen and (min-width: 1700px) {
  .container {
    zoom: 1.68;
  }
  .titleCont{
    zoom: 1.68;
  }
}
